<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-07 10:26:16
 * @LastEditTime: 2021-05-12 14:48:15
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="plan-summary">
    <LogoTitle msg="计划总结"></LogoTitle>
    <FileList></FileList>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>